import "core-js/modules/es.array.push.js";
import { uploadImgUrl, product_host } from '@/common/utils/config.js';
import vueQr from 'vue-qr';
export default {
  components: {
    vueQr
  },
  data() {
    return {
      uploadImgUrl: uploadImgUrl,
      dialogVisible: false,
      previewImgDialog: false,
      //预览图片
      dialogImageUrl: '',
      //预览图片地址

      inforDetail: '',
      //信息详情
      skuData: '',
      //
      thumb: [],
      //
      commentList: [],
      //评论列表
      totalComment: '',
      //所有评论数

      video: '',
      //视频地址
      cover: '',
      //封面地址

      content: '',
      //举报内容
      img_list: [],
      //上传图片列表

      isFlag: false,
      pageNo: 1,
      pageSize: 10,
      bannerArr1: [],
      bannerArr2: [],
      lang: '',
      wechatVisible: false,
      //复制微信号

      shareImageUrl: '',
      //页面地址

      contacts: '',
      //联系人
      wechat: '',
      //微信号
      whatsApp: '',
      //whatsApp
      mobile: '',
      //手机号
      user_email: '',
      //邮箱号
      company_name: '',
      //公司名称

      detailUserInfor: '',
      //发布信息的用户信息
      information_id: '',
      shareHref: '',
      //分享的链接

      wechatShareUrl: '',
      logoSrc: 'https://www.publichino.com/uploads/images/d1/13b60ffc609e27500ae05e1d16121a.png',
      subject: '巴拿马中文广告平台-Publichino.com',
      zhInterest: '我对您的广告很有感兴趣，请您联系我！',
      spInterest: 'Estoy muy interesado en su anuncio, por favor contacte conmigo!'
    };
  },
  mounted() {
    //  this.$refs.page.scrollTop=0;
    this.information_id = this.$route.query.information_id;
    this.lang = localStorage.getItem('lang');
    this.wechatShareUrl = 'https://www.publichino.com/weChatShare?information_id=' + this.information_id;
    this.getInforDetailDetail();
    this.getComments();
    this.getAds(1, 10);
    this.getAds(2, 11);
    window.addEventListener("setItemEvent", e => {
      if (e.key === "lang") {
        this.lang = e.newValue;
        this.getInforDetailDetail();
      }
    });
    this.shareHref = window.location.href + '&lang=' + this.lang;
    // this.scrollToTop();
    window.scrollTo(0, 0);
  },
  methods: {
    // 轮播图切换事件
    bannerArrChange(item) {
      console.log(item);
      let ads_type = item.ads_type;
      let bind_information_id = item.bind_information_id;
      let href = item.href;
      if (ads_type == 1) {
        if (href) {
          window.open(href);
        }
      } else {
        this.$router.push({
          path: '/home/detail',
          query: {
            information_id: bind_information_id
          }
        });
      }
    },
    // 复制微信号
    copyClick() {
      var clipboard = new this.Clipboard('.copy_right');
      clipboard.on('success', e => {
        this.$message.success(this.$t('copySuccess'));
        // 释放内存  
        clipboard.destroy();
      });
    },
    // 举报内容点击
    textareaClick() {
      this.$nextTick(() => {
        this.$refs.textareaRef.focus();
      });
    },
    // 获取轮播图
    getAds(type, typeid) {
      this.$http.getAds({
        typeid
      }).then(res => {
        if (res.code == 1) {
          switch (type) {
            case 1:
              this.bannerArr1 = res.data;
              break;
            case 2:
              this.bannerArr2 = res.data;
              break;
          }
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 获取信息详情
    getInforDetailDetail() {
      let userId = this.$store.state.userinfo.id;
      console.log(userId);
      this.$http.informationDetail({
        user_id: userId,
        information_id: this.information_id
      }).then(res => {
        if (res.code == 1) {
          this.inforDetail = res.data;
          this.skuData = res.data.sku_data;
          console.log("嘿嘿嘿", res.data.sku_data);
          let newSkuData = [];
          this.skuData.forEach((item, index) => {
            if (item.information_type == 1 && !item.value || item.information_type == 3 && !item.value) {} else {
              newSkuData.push(item);
            }
          });
          newSkuData.forEach(item => {
            if (item.sign == 'Precio') {
              item.value = '$' + item.value;
            }
            if (item.sign == 'Mantenimientos') {
              item.value = '$' + item.value;
            }
          });
          this.skuData = newSkuData;
          console.log("数组呀", this.skuData);
          this.thumb = res.data.thumb;
          this.video = res.data.video;
          this.cover = res.data.cover;

          // 联系方式
          this.detailUserInfor = this.inforDetail.user_info;
          this.contacts = this.detailUserInfor.contacts;
          this.mobile = this.detailUserInfor.mobile_code + this.detailUserInfor.mobile;
          this.whatsApp = this.detailUserInfor.whats_code + this.detailUserInfor.whats_app;
          this.wechat = this.detailUserInfor.wechat;
          this.user_email = this.detailUserInfor.user_email;
          this.company_name = this.detailUserInfor.company_name;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取评论
    getComments() {
      this.$http.commentInformationList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        information_id: this.information_id
      }).then(res => {
        if (res.code == 1) {
          this.totalComment = res.data.total;
          this.commentList = this.commentList.concat(res.data.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backClick() {
      // this.$router.push('/');
      this.$router.go(-1);
    },
    // 图片删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.previewImgDialog = true;
    },
    // 上传之前
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      if (fileType === '.jpg' || fileType === '.png' || fileType === '.jpeg' || fileType === '.bmp' || fileType === '.gif') {} else {
        this.$message.error(this.$t('pleaseUploadTheCorrectImageType'));
        return false;
      }
    },
    // 上传图片成功
    onChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.img_list = fileList;
    },
    // 分享
    shareClick(val) {
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t('other.noLoginPleaseToLogin'));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin"
          });
        }, 1500);
        return;
      }
      switch (val) {
        case 1:
          break;
        case 2:
          break;
        case 3:
          let whatsAppShareUrl = 'https://api.whatsapp.com/send?text=' + this.shareHref;
          window.open(whatsAppShareUrl);
          break;
        case 4:
          let emailShareUrl = "mailto:?body=" + this.shareHref + '&subject=' + this.subject;
          window.location.href = emailShareUrl;
          break;
      }
    },
    // 联系发布人
    contactClick(val) {
      switch (val) {
        case 1:
          this.wechatVisible = true;
          break;
        case 2:
          let whatsApp = '+' + this.whatsApp;
          let contactHref = this.zhInterest + '\n' + this.spInterest + '\n' + this.shareHref;
          let whatsAppContactUrl = 'https://api.whatsapp.com/send?phone=' + whatsApp + '&text=' + encodeURIComponent(contactHref);
          ;
          window.open(whatsAppContactUrl);
          break;
        case 3:
          break;
        case 4:
          let body = this.zhInterest + '%0A' + this.spInterest + '%0A' + this.$t('advertiseLink') + ':' + '%0A' + this.shareHref;
          let emailContactUrl = "mailto:" + this.user_email + '?subject=' + this.subject + '&body=' + body;
          window.location.href = emailContactUrl;
          break;
      }
    },
    //提价举报
    toInformClick() {
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t('other.noLoginPleaseToLogin'));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin"
          });
        }, 1500);
        return;
      }
      this.dialogVisible = true;
    },
    // 提交举报
    btnClick() {
      if (this.isFlag) return;
      if (!this.content) {
        this.$message.error(this.$t('tips.theReportContentCannotBeEmpty'));
        return;
      }
      if (this.img_list.length == 0) {
        this.$message.error(this.$t('tips.photoCannotBeEmpty'));
        return;
      }
      this.isFlag = true;
      let thumb = this.img_list.map(item => item.response.data[0].id).join(',');
      console.log(thumb);
      let data = {
        content: this.content,
        information_id: this.information_id,
        thumb
      };
      this.$http.inform(data).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.isFlag = false;
          this.dialogVisible = false;
        } else {
          this.isFlag = false;
          this.$message.error(res.msg);
        }
      });
    },
    //查看更多
    checkMore() {
      this.pageNo++;
      this.getComments();
    },
    // 收藏和取消收藏
    collectClick() {
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t('other.noLoginPleaseToLogin'));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin"
          });
        }, 1500);
        return;
      }
      let type = this.inforDetail.is_collection == 0 ? 1 : 2;
      this.$http.collectionInformation({
        information_id: this.information_id,
        type
      }).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.getInforDetailDetail();
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 我也说一句
    sayClick() {
      if (!this.$store.state.userinfo) {
        this.$message.error(this.$t('other.noLoginPleaseToLogin'));
        setTimeout(() => {
          this.$router.push({
            path: "/accountLogin"
          });
        }, 1500);
        return;
      }
      console.log(this.$store.state.userinfo);
      if (this.release_userId == this.$store.state.userinfo.id) {
        this.$message.error(this.$t('home.commentText'));
        return false;
      }
      this.$prompt(this.$t('home.inputComment'), this.$t('my.tips'), {
        confirmButtonText: this.$t('home.send'),
        cancelButtonText: this.$t('cancel'),
        inputPlaceholder: this.$t('home.inputComment'),
        inputPattern: /^\S/,
        inputErrorMessage: this.$t('tips.commentsCannotBeEmpty')
      }).then(({
        value
      }) => {
        this.postComment(value);
      }).catch(() => {});
    },
    // 发表评论
    postComment(value) {
      console.log("0-------8888");
      console.log("评论来了哈哈哈");
      if (!value) {
        this.$message.info(this.$t('tips.commentsCannotBeEmpty'));
        return;
      }
      this.$http.addComment({
        content: value,
        information_id: this.information_id
      }).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.pageNo = 1;
          this.commentList = [];
          this.getComments();
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "all_detail"
  }, [_c('div', {
    staticClass: "detail"
  }, [_c('div', {
    staticClass: "detail_left"
  }, [_c('div', {
    staticClass: "detail_left_one"
  }, [_c('el-carousel', {
    attrs: {
      "trigger": "click",
      "height": "440px",
      "arrow": "always"
    }
  }, _vm._l(_vm.bannerArr1, function (item) {
    return _c('el-carousel-item', {
      key: item.id
    }, [_c('img', {
      attrs: {
        "src": item.thumb
      },
      on: {
        "click": function ($event) {
          return _vm.bannerArrChange(item);
        }
      }
    })]);
  }), 1)], 1), _c('div', {
    staticClass: "detail_left_one"
  }, [_c('el-carousel', {
    attrs: {
      "trigger": "click",
      "height": "440px",
      "arrow": "always"
    }
  }, _vm._l(_vm.bannerArr2, function (item) {
    return _c('el-carousel-item', {
      key: item.id
    }, [_c('img', {
      attrs: {
        "src": item.thumb
      },
      on: {
        "click": function ($event) {
          return _vm.bannerArrChange(item);
        }
      }
    })]);
  }), 1)], 1)]), _c('div', {
    staticClass: "detail_right"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "top_left"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": _vm.backClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('other.revertText')) + " ")]), _vm._m(0)]), _c('div', {
    staticClass: "top_right"
  }, [_c('div', {
    staticClass: "all_shares"
  }, [_c('div', {
    staticClass: "share_text"
  }, [_vm._v(_vm._s(_vm.$t('home.share')) + "：")]), _c('div', {
    staticClass: "all_share_items"
  }, [_c('el-dropdown', {
    attrs: {
      "trigger": "click"
    }
  }, [_c('div', {
    staticClass: "el-dropdown-link share_item",
    on: {
      "click": function ($event) {
        return _vm.shareClick(1);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/share_wexin.png")
    }
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('div', {
    staticClass: "qr_code"
  }, [_c('div', [_c('vue-qr', {
    attrs: {
      "text": _vm.wechatShareUrl,
      "size": 148,
      "logoSrc": _vm.logoSrc
    }
  })], 1), _c('div', {
    staticClass: "scan_text"
  }, [_vm._v(_vm._s(_vm.$t('weChatScan')))])])])], 1), _c('div', {
    staticClass: "share_item",
    on: {
      "click": function ($event) {
        return _vm.shareClick(3);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/share_whatsapp.png")
    }
  })]), _c('div', {
    staticClass: "share_item",
    staticStyle: {
      "margin-right": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.shareClick(4);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/share_email.png")
    }
  })])], 1)]), _c('div', {
    staticClass: "item",
    on: {
      "click": _vm.collectClick
    }
  }, [_c('div', {
    staticClass: "item_img"
  }, [_vm.inforDetail.is_collection == 0 ? _c('img', {
    attrs: {
      "src": require("@/assets/home_icons/collect.png")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/home_icons/collected.png")
    }
  })]), _c('div', [_vm._v(_vm._s(_vm.$t('home.collect')))])]), _c('div', {
    staticClass: "item",
    on: {
      "click": _vm.toInformClick
    }
  }, [_vm._m(1), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('home.report')))])])])]), _c('div', {
    staticClass: "carousel"
  }, [_c('el-carousel', {
    attrs: {
      "trigger": "click",
      "height": "350px",
      "arrow": "always"
    }
  }, [_vm.video ? _c('el-carousel-item', [_c('video', {
    attrs: {
      "width": "894",
      "height": "350",
      "src": _vm.video,
      "poster": _vm.cover,
      "controls": "",
      "autoplay": true,
      "muted": ""
    },
    domProps: {
      "muted": true
    }
  })]) : _vm._e(), _vm._l(_vm.thumb, function (item, index) {
    return _c('el-carousel-item', {
      key: index
    }, [_c('viewer', {
      attrs: {
        "images": [item]
      }
    }, [_c('img', {
      key: index,
      staticStyle: {
        "object-fit": "contain",
        "width": "894px",
        "height": "350px"
      },
      attrs: {
        "src": item
      }
    })])], 1);
  })], 2)], 1), _c('div', {
    staticClass: "house_content"
  }, [_c('div', {
    staticClass: "house_title"
  }, [_c('div', {
    staticClass: "house_title_left"
  }, [_c('div', {
    staticClass: "house_name"
  }, [_vm._v(" " + _vm._s(_vm.inforDetail.title) + " ")]), _c('div', {
    staticClass: "cate_name"
  }, [_vm._v(_vm._s(_vm.inforDetail.cate_name))]), _c('div', {
    staticClass: "house_two"
  }, [_vm.inforDetail.price != '0.00' ? _c('div', {
    staticClass: "price"
  }, [_vm._v("$" + _vm._s(_vm.inforDetail.price))]) : _vm._e(), _c('div', {
    staticClass: "price_right"
  }, [_vm.inforDetail.company_logo_url ? _c('img', {
    attrs: {
      "src": _vm.inforDetail.company_logo_url
    }
  }) : _vm._e()])])]), _c('div', {
    staticClass: "house_title_right"
  }, [_vm.wechat ? _c('div', {
    staticClass: "contact_item",
    on: {
      "click": function ($event) {
        return _vm.contactClick(1);
      }
    }
  }, [_vm._m(2), _c('div', {
    staticClass: "contact_item_text"
  }, [_vm._v(" " + _vm._s(_vm.$t('weiXinContact')) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "contact_item",
    on: {
      "click": function ($event) {
        return _vm.contactClick(2);
      }
    }
  }, [_vm._m(3), _c('div', {
    staticClass: "contact_item_text"
  }, [_vm._v(" WhatsApp ")])]), _c('div', {
    staticClass: "contact_item",
    on: {
      "click": function ($event) {
        return _vm.contactClick(4);
      }
    }
  }, [_vm._m(4), _c('div', {
    staticClass: "contact_item_text"
  }, [_vm._v(" " + _vm._s(_vm.$t('emailContact')) + " ")])])])]), _c('div', {
    staticClass: "house_info"
  }, [_c('div', {
    staticClass: "house_view"
  }, [_vm._m(5), _vm._v(" " + _vm._s(_vm.inforDetail.look_count) + " ")]), _c('div', {
    staticClass: "house_time"
  }, [_vm._v(" " + _vm._s(_vm.$t('home.releaseTime')) + " " + _vm._s(_vm.inforDetail.create_time) + " ")]), _c('div', {
    staticClass: "house_id"
  }, [_vm._v(" ID: " + _vm._s(_vm.information_id) + " ")]), _c('div', {
    staticClass: "address"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/house.png")
    }
  }), _c('div', {
    staticClass: "address_detail"
  }, [_vm._v(" " + _vm._s(_vm.inforDetail.addr_name) + " " + _vm._s(_vm.inforDetail.area_name) + " ")])])])]), _c('div', {
    staticClass: "all_info"
  }, [_vm._l(_vm.skuData, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "info_item"
    }, [_c('div', {
      staticClass: "info_item_title"
    }, [_vm._v(_vm._s(item.key_name))]), _c('div', {
      staticClass: "info_item_content"
    }, [_vm._v(_vm._s(item.select_value ? item.select_value : item.value))])]);
  }), _vm.company_name ? _c('div', {
    staticClass: "info_item"
  }, [_c('div', {
    staticClass: "info_item_title"
  }, [_vm._v(_vm._s(_vm.$t('companyName')))]), _c('div', {
    staticClass: "info_item_content"
  }, [_vm._v(_vm._s(_vm.company_name))])]) : _vm._e(), _vm.mobile ? _c('div', {
    staticClass: "info_item"
  }, [_c('div', {
    staticClass: "info_item_title"
  }, [_vm._v(_vm._s(_vm.$t('mobile')))]), _c('div', {
    staticClass: "info_item_content"
  }, [_vm._v(_vm._s(_vm.mobile))])]) : _vm._e(), _vm.contacts ? _c('div', {
    staticClass: "info_item"
  }, [_c('div', {
    staticClass: "info_item_title"
  }, [_vm._v(_vm._s(_vm.$t('contacts')))]), _c('div', {
    staticClass: "info_item_content"
  }, [_vm._v(_vm._s(_vm.contacts))])]) : _vm._e()], 2), _c('div', {
    staticClass: "home_detail"
  }, [_c('div', {
    staticClass: "home_detail_content"
  }, [_vm._v(" " + _vm._s(_vm.inforDetail.detail) + " ")]), _c('div', {
    staticClass: "home_detail_imgs"
  }, [_c('div', {
    staticClass: "detail_img"
  }, [_c('viewer', {
    attrs: {
      "images": _vm.thumb
    }
  }, _vm._l(_vm.thumb, function (item, index) {
    return _c('img', {
      key: index,
      attrs: {
        "src": item
      }
    });
  }), 0)], 1)])]), _c('div', {
    staticClass: "detail_comments"
  }, [_c('div', {
    staticClass: "detail_comments_top"
  }, [_vm._m(6), _c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t('title.allComments')) + "(" + _vm._s(_vm.totalComment) + ")")])]), _c('div', {
    staticClass: "all_comments"
  }, _vm._l(_vm.commentList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "comment_item"
    }, [_c('div', {
      staticClass: "comment_item_top"
    }, [_c('div', {
      staticClass: "comment_item_top_left"
    }, [_c('img', {
      attrs: {
        "src": item.user_head_img
      }
    })]), _c('div', {
      staticClass: "comment_item_top_right"
    }, [_c('div', {
      staticClass: "comment_name"
    }, [_vm._v(_vm._s(item.user_name))]), _c('div', {
      staticClass: "comment_time"
    }, [_vm._v(_vm._s(item.create_time))])])]), _c('div', {
      staticClass: "comment_item_bottom"
    }, [_vm._v(" " + _vm._s(item.content) + " ")])]);
  }), 0)]), _c('div', {
    staticClass: "detail_bottom"
  }, [_c('div', {
    staticClass: "detail_bottom_left",
    on: {
      "click": _vm.checkMore
    }
  }, [_vm.totalComment > _vm.commentList.length ? [_vm._v(" " + _vm._s(_vm.$t('checkMore')))] : _vm._e()], 2), _c('div', {
    staticClass: "detail_bottom_right",
    on: {
      "click": _vm.sayClick
    }
  }, [_vm.lang == 'zh' ? _c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/say.png")
    }
  }) : _c('img', {
    staticStyle: {
      "width": "180px"
    },
    attrs: {
      "src": require("../../../assets/home_icons/say_bnm.png")
    }
  })])])])]), _c('el-dialog', {
    attrs: {
      "title": "",
      "visible": _vm.dialogVisible,
      "width": "38%",
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('div', {
    staticClass: "title_all"
  }, [_c('div', {
    staticClass: "title_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]), _c('div', [_vm._v(_vm._s(_vm.$t('home.report')))])])]), _c('div', {
    staticClass: "dialog_content"
  }, [_c('div', {
    staticClass: "inform_title"
  }, [_vm._v(_vm._s(_vm.$t('home.reportContent')))]), _c('div', {
    staticClass: "textarea",
    on: {
      "click": _vm.textareaClick
    }
  }, [_c('el-input', {
    ref: "textareaRef",
    attrs: {
      "type": "textarea",
      "autosize": true,
      "placeholder": _vm.$t('home.provideMoreInformation'),
      "maxlength": "200"
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  }), _c('div', {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.content.length) + "/200")])], 1), _c('div', {
    staticClass: "inform_title"
  }, [_vm._v(_vm._s(_vm.$t('home.uploadPhoto')))]), _c('div', {
    staticClass: "all_imgs"
  }, [_c('el-upload', {
    attrs: {
      "action": _vm.uploadImgUrl,
      "list-type": "picture-card",
      "on-preview": _vm.handlePictureCardPreview,
      "on-remove": _vm.handleRemove,
      "limit": 3,
      "before-upload": _vm.beforeUpload,
      "on-change": _vm.onChange,
      "multiple": true
    }
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _c('div', {
    staticClass: "el-upload__tip",
    attrs: {
      "slot": "tip"
    },
    slot: "tip"
  }, [_vm._v(_vm._s(_vm.$t('home.atMost')) + "3" + _vm._s(_vm.$t('home.open')))])]), _c('el-dialog', {
    attrs: {
      "visible": _vm.previewImgDialog,
      "modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.previewImgDialog = $event;
      }
    }
  }, [_c('img', {
    attrs: {
      "width": "100%",
      "src": _vm.dialogImageUrl,
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.btnClick
    }
  }, [_vm._v(_vm._s(_vm.$t('home.submit')))]), _c('div', {
    staticClass: "tip"
  }, [_vm._v(_vm._s(_vm.$t('home.informTips')))])])]), _c('el-dialog', {
    attrs: {
      "title": "",
      "visible": _vm.wechatVisible,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.wechatVisible = $event;
      }
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('div', {
    staticClass: "title_all"
  }, [_c('div', {
    staticClass: "title_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]), _c('div', [_vm._v(_vm._s(_vm.$t('weiXinContact')))])])]), _c('div', {
    staticClass: "wechat"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/weixin.png")
    }
  }), _vm._v(" " + _vm._s(_vm.$t('contactWXId')) + " ")]), _c('div', {
    staticClass: "copy"
  }, [_c('div', {
    attrs: {
      "id": "copy_left"
    }
  }, [_vm._v(_vm._s(_vm.wechat))]), _c('div', {
    staticClass: "copy_right",
    attrs: {
      "data-clipboard-target": "#copy_left"
    },
    on: {
      "click": _vm.copyClick
    }
  }, [_vm._v(_vm._s(_vm.$t('copy')))])])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/front.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/inform.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "contact_item_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/weixin.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "contact_item_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/phone2.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "contact_item_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/email.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "house_view_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/view.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/title_logo.png")
    }
  })]);
}];
export { render, staticRenderFns };